

.firstButtonBox{
    width: 100%;
    height: 330px;

    &__title{
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;
        color: #4f4f4f;
    }

    &__list{
        margin: 28px 0 0 0 ;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 45px;
        
    }
    
    &__el{
        max-width: 50%;
        width: 50%;
    }
    &__btn{
        margin: 12px 0 0 0;
        padding: 8px 16px;
        border-radius: 6px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #3f3f3f;
       
        border: none;

        &--hover{
            background-color: #AEAEAE;
        }
        &--shadow{
            box-shadow: 0px 2px 3px rgba(51, 51, 51, 0.2);
        }
    }
    &__btnOutline{
        border: 1px solid #3D5AFE ;
        background-color: #fff;
        color: #3D5AFE;

        &--hover{
            background-color: #2962FF1A;
        }
    }
    &__btnTxt{
        border: none;
        background-color: #fff;
        color: #3D5AFE;

        &--hover{
            background-color: #2962FF1A;
            color: #3D5AFE;
            
        }
    }

    &__txt{
        font-family: 'Ubuntu Mono', monospace;
        font-size: 12px;
        line-height: 100%;
        font-weight: 400;
        color: #333333;
        &--hover{
            color: #828282;
        }
    }
    
}

@media(min-width:1000px){
    
.firstButtonBox{
    width: 410px;
    height: 330px;

    &__title{
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 500;
        line-height: 120%;
        color: #4f4f4f;
    }

    &__list{
        margin: 28px 0 0 0 ;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 45px;
        
    }
    &__el{
        max-width: 50%;
        width: 50%;
    }
    &__btn{
        margin: 12px 0 0 0;
        padding: 8px 16px;
        border-radius: 6px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #3f3f3f;
       
        border: none;

        &--hover{
            background-color: #AEAEAE;
        }
        &--shadow{
            box-shadow: 0px 2px 3px rgba(51, 51, 51, 0.2);
        }
    }
    &__btnOutline{
        border: 1px solid #3D5AFE ;
        background-color: #fff;
        color: #3D5AFE;

        &--hover{
            background-color: #2962FF1A;
        }
    }
    &__btnTxt{
        border: none;
        background-color: #fff;
        color: #3D5AFE;

        &--hover{
            background-color: #2962FF1A;
            color: #3D5AFE;
            
        }
    }

    &__txt{
        font-family: 'Ubuntu Mono', monospace;
        font-size: 12px;
        line-height: 100%;
        font-weight: 400;
        color: #333333;
        &--hover{
            color: #828282;
        }
    }
    
}
}