

.gridButtonBox{
    margin: 45px 0 0 0 ;
    width: 100%;
    height: 780px;

    &__list{
        width: 100%;
        height: 220px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
    }
    
    &__el:nth-child(1){
        grid-area: b1;
    }
    &__el:nth-child(2){
        grid-area: b2;
    }
    &__el:nth-child(3){
        grid-area: b3;
    }
    &__disShadow{
        color: #fff;
        background-color: #3d5afe;
        
    }
    &__disabled{
        background-color: #E0E0E0;
        color: #9E9E9E;
       

        &--textD{
            background-color: #fff;
            color: #9E9E9E;
        }
    }
}

.gridBtnIcon{

    
    &__list{
        margin: 45px 0 0 0 ;
        width: 100%;
        height: 146px;
        display: flex;
        flex-direction: column;
        list-style: none;
        justify-content: space-between;
        

    }
    
    &__btn{
        margin: 12px 0 0 0 ;
        padding: 8px 16px;
        border-radius: 6px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        background-color: #2962ff;
        border: none;
    }
    &__icon{
        font-size: 14;
        color: #fff;
        margin: 0px 10px 0 0  ;
        padding: 1px 0 0 0;
        
        &--reverse{
            margin:0 0 0 10px;
        }
        
    }
}

.listBtnSize{
    
    &__list{
        margin: 85px 0 0 0 ;
        width: 100%;
        height: 280px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        list-style: none;
    }
    
    &__btn{
        margin: 17px 0 0 0;
        padding: 6px 12px;
        border-radius: 6px;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        background-color: #2962ff;
        border: none;
        &--md{
            margin: 15px 0 0 0;
            padding: 8px 16px;
        }
        &--lg{
            margin: 12px 0 0 0;
            padding: 11px 22px;
        }
    }
}


@media(min-width:1000px){
    .gridButtonBox{
        margin: 45px 0 0 0 ;
        width: 445px;
        height: 460px;
    
        &__list{
            width: 100%;
            height: 220px;
            display: grid;
            grid-template-columns: 144px 204px;
            column-gap: 60px;
            grid-template-rows: repeat(2, 60px);
            row-gap: 45px;
            grid-template-areas:
            "b1  . "
            "b2  b3" ;
            list-style: none;
        }
    }
    .gridBtnIcon{

        &__list{
            width: 560px;
            height: 66px;
            display: flex;
            flex-direction: row;
            list-style: none;
            justify-content: space-between;
    
        }
    }

    .listBtnSize{
    
        &__list{
            margin: 45px 0 0 0 ;
            width: 535px;
            height: 66px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            list-style: none;
        }
    }
}