

.App {
    display: flex;
    flex-direction: column;
}
@media(min-width:700px){
    .App {
        display: flex;
        flex-direction: row;
    }
}
