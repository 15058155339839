

.inputIcon{

    &__list{
        margin: 56px 0 0 0 ;
        list-style: none;
        width: 90vw;

    }
    &__el{
        margin: 42px 0 0 0 ;
    }

    &__box{
        display: flex;
        flex-direction: row;
        border: 1px solid #828282;
        width: 100%;
        height: 54px;
        border-radius: 8px;

    }
    &__icon{
        margin: auto 0 auto 12px;
        &--end{
            margin: auto 12px auto 0;
        }
    }
    &__input{
        border: none;
        margin: 0;
        height: 54px;

        &--txt{
            font-weight: 500;
            color: #333333;
            height: 54px;
            font-size: 14px;
        }
    }
    
}
.inputSize{

    display: flex;
    flex-direction: column;
    width: 90vw;
    height: 220px;
    list-style: none;
    margin: 42px 0 0 0 ;

    &> p {
        margin: 0 0 16px 0 ;
    }
    &__label{
        margin: 16px 0 0 0 ;
    }

    &__el{
        height: 105px;
        width: 100%;

    }
    &__input{
        height: 40px;

        &--full{
            width: 100%;
        }
    }
    &__full{
        width: 60vw;
        margin: 42px 0 0 0 ;
    }
    &__txt{
        margin: 0 0 16px 0;
    }
}

.inputText{
    width: 200px;
    margin: 42px 0 0 0 ;
    & > label{
        font-family: 'Noto Sans JP', sans-serif;
            font-weight: 400;
            font-size: 12px;
            color: #333333;
            margin: 16px 0 0 0 ;
    }
    &__textarea{
        padding: 18px 12px;
        margin: 4px 0 0 0 ;
        width: 200px;
        height: 150px;
        border-radius: 8px;
        border: 1px solid #828282;
        font-family: 'Noto Sans JP', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #828282;
    }
}

@media(min-width:1000px){


    .inputIcon{

        &__list{
            margin: 0 0 0 0 ;
            list-style: none;
            width: 90vw;
            display: flex;
            flex-direction: row;
            column-gap: 120px;
            width: 560px;
            flex-wrap: wrap;
            height: 260px;
    
        }
        &__el{
            margin: 0 0 0 0 ;
        }
    
        &__box{
            display: flex;
            flex-direction: row;
            border: 1px solid #828282;
            width: 100%;
            height: 54px;
            border-radius: 8px;
    
        }
        &__icon{
            margin: auto 0 auto 12px;

            &--end{
                margin: auto 12px auto 0;
            }
        }
        &__input{
            border: none;
            margin: 0;
            height: 54px;
    
            &--txt{
                font-weight: 500;
                color: #333333;
                height: 54px;
                font-size: 14px;
            }
            
        }
    }

    .inputSize{

        display: flex;
        flex-direction: row;
        width: 560px;
        height: 105px;
        list-style: none;
        margin: 42px 0 0 0 ;
    
        &__label{
            margin: 16px 0 0 0 ;
        }
    
        &__el{
            height: 105px;
            width: 100%;
            display: flex;
            flex-direction: column;
    
        }
        &__input{
            height: 40px;
            width: 200px;
        }
    }

}