
/* pc version */

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 36px;


    &__title{
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
        font-weight: 600;
        color: #090F31;
        width: 114px;
        text-align: center;
        margin: 16px 0 0 12px ;
        &--color{
            color: #F7542E;
        }
    }
    &__list{
        display: none;
        &--actif{
        display: flex;
        flex-direction: column;
        height: 50vh;
        width: 100%;
        position: fixed;
        background-color: #fff;
        justify-content: space-evenly;
        padding: 25vh 5vw;
        list-style: none;
        }

    }
    &__el{
        margin: 35px 0;
        width: 100%;
        height: 20px;
    }
    &__link{
        color: #9E9E9E;
        font-weight: 500;
        font-size: 14px;
        font-family: 'Noto Sans JP', sans-serif;
        text-decoration: none;
        transition: 0.3s;
        padding: 5px 20px 5px 56px;
        text-align: center;
        &--actif{
            font-weight: 700;
            color: #090F31;
        }
    }

    &__btnClo{
        width: 18px;
        height: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        top: 16px;
        right: 36px;
        z-index: 22;
        
    }
    &__btnBox{
        width: 100%;
        height: 3px;
        border-radius: 3px;
        background-color: #090F31;
       
    }
}


@media(min-width:1000px){
    .navbar{
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 230px;
        height: 100%;
        justify-content: flex-start;
        &__title{
            width: 100%;
            margin: 46px 0 0 0 ;
        }
    
        &__list{
            list-style: none;
            margin: 107px 0 0 0 ;
            display: flex;
            flex-direction: column;
            

        }
        &__el{
            margin: 15px 0;
            width: 100%;
            height: 20px;
        }
        &__link{
            color: #9E9E9E;
            font-weight: 500;
            font-size: 14px;
            font-family: 'Noto Sans JP', sans-serif;
            text-decoration: none;
            transition: 0.3s;
            padding: 5px 20px 5px 56px;
            &--actif{
                font-weight: 700;
                color: #090F31;
            }
        }
        &__link:hover{
            transition: 0.3s;
            color: #090F31;
        }
        &__btnClo{
            display: none;
        }
    }
}